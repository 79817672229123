import Swiper, { Navigation } from "swiper";
import _vars from "../_vars";

function scrollToAnchor() {
  const anchors = document.querySelectorAll('a[href*="#"]');

  for (let anchor of anchors) {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      const blockID = anchor.getAttribute("href").substr(1);

      document.getElementById(blockID).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
  }
}

function anyHover(buttons) {
  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      button.classList.add("hover");

      setTimeout(() => {
        button.classList.remove("hover");
      }, 500);
    });
  });
}

function faqAccordions(accordions) {
  accordions.forEach(function (t) {
    t.addEventListener("click", function (t) {
      var c = t.currentTarget,
        e = document.querySelectorAll(".faq__acc-content"),
        l = c.querySelector(".faq__acc-content");
      accordions.forEach(function (t) {
        t != c && t.classList.remove("active");
      }),
        e.forEach(function (t) {
          t.style.maxHeight = null;
        }),
        c.classList.toggle("active"),
        c.classList.contains("active")
          ? (l.style.maxHeight = l.scrollHeight + 60 + "px")
          : (l.style.maxHeight = null);
    });
  });

  _vars.faqAccs[0].click();
}

const reviewsSlider = new Swiper('.reviews__slider', {
  modules: [Navigation],

  spaceBetween: 20,
  navigation: {
    nextEl: '.reviews__slider-next',
    prevEl: '.reviews__slider-prev'
  },

  speed: 700,
  loop: true,

  breakpoints: {
    320: {
      slidesPerView: 1,
    },

    767: {
      slidesPerView: 'auto',
    },

    1169: {
      slidesPerView: 2,
    }
  }
})

scrollToAnchor();
if(window.innerWidth <= 1169) {
  anyHover(document.querySelectorAll("button"));
}
faqAccordions(_vars.faqAccs);
